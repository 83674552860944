import "core-js/modules/es.array.push.js";
import { baseActiveOfSchoolPage } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      goodsCodeValue: [],
      pageInfo: {
        pageSize: 10,
        name: '',
        state: 1,
        type: 1,
        current: 1,
        total: 0,
        active_goods_type_code1: '',
        active_goods_type_code2: ''
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.active_goods_type_code1 = '';
      this.pageInfo.active_goods_type_code2 = '';
      this.goodsCodeValue = [];
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    seleType(e) {
      this.pageInfo.active_goods_type_code1 = e[0] || '';
      this.pageInfo.active_goods_type_code2 = e[1] || '';
    },
    getList() {
      baseActiveOfSchoolPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/school/schoolActive/activeBaseInfo",
        query: {
          Info: data
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};